<template>
	<div data-route>
		<page-header
			heading="Create new lesson"
		/>
		<div data-element="main">
			<lesson-edit
				:isCreate="true"
			/>
		</div>
	</div>
</template>

<script>

	import PageHeader  from '@/components/ui/PageHeader';
	import LessonEdit  from '@/components/lessons/LessonEdit';

	export default {
		metaInfo: {
			title: 'Create new lesson'
		},
		components: {
			PageHeader,
			LessonEdit
		},
		computed: {
			getCourseId () {
				return parseInt(this.$route.params.courseId);
			},
			getBreadcrumbRoute () {
				if (!this.getCourseId) {
					return false;
				}
				return `/teaching/courses/${this.getCourseId}/lessons`;
			}
		}
	};

</script>
